import Vue from 'vue';
import SvgIcon from '../../store-customization-v2/components/SvgIcon.vue';

new Vue({
    el: '.payt-cotainer-svg-icons-mobile',
    components: { SvgIcon },
    data(){
        return{
            currentSlide: 0,
            totalSliders: 0,
            btnScrollLeft: null,
            btnScrollRight: null,
        }
    },
    methods:{
        goPrev(){
            this.currentSlide--;
        
            this.updateMargin();
        },
        goNext(){
            this.currentSlide++; 
        
            this.updateMargin();
        },
        updateMargin(){
            if(this.currentSlide == 0){
                this.btnScrollLeft = false;
            }else{
                this.btnScrollLeft = true;
            }
    
            if(this.currentSlide == (this.totalSliders - 1)){
                this.btnScrollRight = false;
            }else{
                this.btnScrollRight = true;
            }
    
            let newMargin = (this.currentSlide * document.body.clientWidth);
            document.querySelector('.carousel--container').style.marginLeft = `-${newMargin}px`;
        }
    },
    mounted(){
        this.totalSliders   = document.querySelectorAll(".wrapper-mobile").length;

        document.querySelector(".carousel--container").style.width = `calc(100vw * ${this.totalSliders})`;

        this.btnScrollLeft = false;
        this.btnScrollRight = true;
    }
});